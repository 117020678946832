import cn from 'classnames';

const RotatingLoader = ({ handleRefreshClick, refreshLoader })=>{
    return(
        <button
            id="iconButton"
            className="inline-flex items-center h-9 rounded-md bg-indigo-600 px-5 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-offset-2 transition-transform duration-300 ease-in-out transform hover:scale-105"
            onClick={handleRefreshClick}
            disabled={refreshLoader}
        >
            <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor "
            className={cn("w-6 h-6", {"animate-spin":refreshLoader})}
            >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
            </svg>
        </button>
    );
}

export default RotatingLoader;
