import * as Yup from 'yup';

export const languagesOptions = [
    {
        label: "Node.js",
        value: 'nodejs'
    },
    {
        label: "Python",
        value: 'python'
    },
    {
        label: ".NET",
        value: 'dotnet'
    },
]

export const validationLoginSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required')
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      'Email must be a valid email address with "@" symbol'
    ),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters long')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
      'Password must contain at least one letter, one number, and one special character'
    )
    .test('not-equal', 'Email and password cannot be the same', function (value) {
      const { email } = this.parent;
      return value !== email;
    }),
});

export const validationCreateuserSchema = Yup.object({
  email: Yup.string()
  .email('Invalid email address')
  .required('Email is required')
  .matches(
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    'Email must be a valid email address with "@" symbol'
  ),
  fullname: Yup.string().required('Full name is required'),
  password: Yup.string()
  .required('Password is required')
  .min(6, 'Password must be at least 6 characters long')
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
    'Password must contain at least one letter, one number, and one special character'
  )
  .test('not-equal', 'Email and password cannot be the same', function (value) {
    const { email } = this.parent;
    return value !== email;
  }),
  role: Yup.string().required('Role is required'),
});

export  const validationSchema = Yup.object().shape({
    variations: Yup.array().of(
      Yup.object().shape({
        variationsPercent: Yup.string().required('Required'),
      })
    ),
    goals: Yup.array().of(
      Yup.object().shape({
        goalTitle: Yup.string().required('Goal Title is required'),
        goalIdentifier: Yup.string()
          .required('Goal Identifier is required')
          .matches(/^\S*$/, 'Goal Identifier should not contain spaces'),
      })
    ), 
      projectName: Yup.string()
      .required('Required'),
      campaignKey: Yup.string()
      .required('Required'),
      controlPercent: Yup.string()
      .required('Required'),
      trafficSplit: Yup.string()
      .required('Required'),
      environments: Yup.string()
      .required('Required'),
  });

 export const initialValues={
    campaignKey: '',
    gaApiKey:"",
    projectName:"",
    environments:'Node.js',
    controlPercent: 50,
    trafficSplit:100,
    variations: [{ variationsPercent: 50,label: "Variation 1", }],
    goals: [{ goalIdentifier: "",goalInput: "Goal 1",trackConversion:"" }
]
  } 