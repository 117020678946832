import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { validationLoginSchema } from "./../constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../constants/toasts";
import axiosInstance from "../apiEndpoints";

const Login = () => {
  const navigate = useNavigate();
  const {loginMessage} = useParams();
  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values) => {
    const { email, password } = values;

    const loginPost = await axiosInstance.post("/users/loginUser", {
      email,
      password,
    });

    if (loginPost.data.data) {
      localStorage.setItem("userDetails", JSON.stringify(loginPost.data.data));
      localStorage.setItem("token", loginPost.data.token);
      toast(loginPost.data.message, successToast);
      navigate("/dashboard");
    } else {
      toast(loginPost.data.message, errorToast);
    }
  };

  return (
    <React.Fragment>
      <section className="">
        <div className="flex items-center justify-center bg-white px-4 py-10 sm:px-6 sm:py-16 lg:px-8">
          <div className="xl:mx-auto xl:w-full xl:max-w-sm 2xl:max-w-md">
            <h2 className="text-2xl font-bold leading-tight text-center text-indigo-700">
              Sign in to your account{" "}
            </h2>
            {loginMessage && (
              <h3 className="text-sm text-center text-yellow-700 mt-3">
                Session is expired, please login again
              </h3>
            )}

            <Formik
              initialValues={initialValues}
              validationSchema={validationLoginSchema}
              onSubmit={handleSubmit}>
              <Form className="mt-8">
                <div className="space-y-5">
                  <div className="mb-4">
                    <label
                      htmlFor=""
                      className="text-base font-medium text-grey-900">
                      {" "}
                      Email address{" "}
                    </label>
                    <div className="mt-2">
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor=""
                        className="text-base font-medium text-gray-900">
                        {" "}
                        Password{" "}
                      </label>
                      {/* <Link
                        className="text-sm font-semibold text-indigo hover:underline">
                        Forgot password?
                      </Link> */}
                    </div>
                    <div className="mt-2">
                      <Field
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        className="flex h-10 w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 text-sm placeholder:text-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-red-500"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="inline-flex w-full items-center justify-center rounded-md bg-indigo-700 px-3.5 py-2.5 font-semibold leading-7 text-white hover:bg-indigo/80">
                      Get started
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </section>
      <ToastContainer />
    </React.Fragment>
  );
};
export default Login;
