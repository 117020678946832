import {useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import BellIcon from "../icons/BellIcon";
import SettingIcon from "../icons/SettingIcon";
const TopNavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const openInNewTab = (url) =>{
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');  
  };

    return (
        <header>
          <nav className="bg-indigo-800 w-full drop-shadow-sm fixed left-0 z-40">
            <div className="mx-auto px-2 sm:px-6 lg:px-2">
              <div className="relative flex h-16 justify-end">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  Mobile menu button
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                  >
                    <span className="sr-only">Open main menu</span>
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                    <svg
                      className="hidden h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* <button
                    type="button"
                    role="link"
                    onClick={() => openInNewTab('file:///C:/ab-testing/User%20Manual%20for%20AB-Testing%20Project%20-%20866b021ba6dd48b49c3e1fa6f6e19266.html')}
                    className="rounded-full bg-indigo-600 p-1 px-3 mx-1 text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-gray-800"
                  >
                    Docs
                  </button> */}
                  <div className="dropdown relative inline-block">
                    <button
                      type="button"
                      className="dropdown-toggle inline-flex h-9 items-center rounded-2xl border border-transparent bg-indigo-600 px-1 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 "
                      onClick={toggleDropdown}
                    >
                     <SettingIcon/>
                    </button>
                      <ul className="dropdown-menu hidden absolute z-10 bg-white text-gray-800 rounded-md shadow-md py-1  right-0">
                        <li
                        onClick={() => navigate(`/profile`)}
                          className="dropdown-item cursor-pointer hover:bg-gray-100 py-1 px-7">
                          Profile
                        </li>
                        <li
                        onClick={handleLogout}
                        className="dropdown-item cursor-pointer hover:bg-gray-100 py-1 px-7">
                          Logout
                        </li>
                        {/* 
                        <li
                        // onClick={() => navigate(`/campaign/}`)}
                          className="dropdown-item cursor-pointer hover:bg-gray-100 py-1 px-7">
                          User Guide
                        </li>
                        */}
                      </ul>
                  </div>
                  {/* <button
                    type="button"
                    className="rounded-full bg-indigo-600 p-1 mx-1 text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-gray-800"
                  >
                  <BellIcon/>
                  </button>      */}
               
                </div>
              </div>
            </div>
          </nav>
        </header>
    )
};

export default TopNavBar;