import React,{useEffect,useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer,toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { successToast } from '../../constants/toasts';
import axiosInstance from '../../apiEndpoints';

import {getLocalStorage} from './../../constants/utility';

const Profile = () => {
  const navigate=useNavigate();
  const [user,setUser]=useState('')

  const userId = getLocalStorage("id");

  useEffect(() => {
    if(!userId) return;
    getData();
  },[]);

  const getData = async() => {
    try{
      const result= await axiosInstance.get(`users/getUserById/${userId}`)
      setUser(result.data.data)
    }catch(err)
    {
      console.log(err)
    }
  };

  if (userId && !user) {
    return <div>Loading...</div>;
  }

const roles = [];

  return (
   <React.Fragment>
        <ToastContainer/>
        <div className="grid pt-6 mt-10">
            <div className="grid bg-gray-100 p-3 ">
                <div className="lg:flex lg:items-center lg:justify-between">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Profile
                        </h2>
                    </div>
                </div>
            </div>
            <div className="form-wrap">
                <Formik
                initialValues={userId ? {
                    fullname:user.fullname,
                    email:user.email,
                    role:user.role
                }:
                    {  fullname: "" ,email: '', role: "" }
                }
                validationSchema={Yup.object(userId ? 
                    {
                    email: Yup.string().required('Email is required'),
                    fullname: Yup.string().required('Full name is required'),
                    role: Yup.string().required('Role is required'),
                    }:
                    {
                    email: Yup.string().required('Email is required'),
                    fullname: Yup.string().required('Full name is required'),
                    role: Yup.string().required('Role is required'),
                    })}

                onSubmit={async(values, { setSubmitting }) => {
                    if(userId)
                    {
                        try{
                            await axiosInstance.put(`users/updateUser/${userId}`, values);
                            toast("Update Successfully",successToast);
                            setSubmitting(false);
                            navigate('/profile'); 
                        }
                        catch(err){
                          console.log(err)
                        }
                    }
                
                    }}
                >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit,isSubmitting }) => (
                    <Form onSubmit={handleSubmit} className="px-8 pt-6 pb-8 my-2 ">
                    <div className='flex justify-center'>
                        <div className="w-3/5 bg-white shadow-inner border border-indigo-700 rounded px-8 pt-6 pb-8 mb-4 ">  
                        <div className="mb-4">
                            <label htmlFor="fullname" className="block text-gray-700 font-bold mb-2">
                            Full Name
                            </label>
                            <Field
                            type="text"
                            name="fullname"
                            id="fullname"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            <ErrorMessage name="fullname">
                            {(msg) => <div className="text-red-500">{msg}</div>}
                            </ErrorMessage>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="fullname" className="block text-gray-700 font-bold mb-2">
                            Email
                            </label>
                            <Field
                            type="email"
                            name="email"
                            disabled={userId? true:false}
                            id="email"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            <ErrorMessage name="email">
                            {(msg) => <div className="text-red-500">{msg}</div>}
                            </ErrorMessage>
                        </div>
                        <div className="mb-4">
                            <label htmlFor="role" className="block text-gray-700 font-bold mb-2">
                            Role
                            </label>
                            <Field
                            type="text"
                            name="role"
                            id="role"
                            disabled={true}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            
                            <ErrorMessage name="role">
                                {(msg) => <div className="text-red-500">{msg}</div>}
                            </ErrorMessage>
                        </div>
                        <div className="flex items-end justify-between">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                Save
                            </button>
                        </div>
                        </div>
                    </div>
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
    </React.Fragment>
);
};

export default Profile;      