import {
  ViewColumnsIcon,
  Square3Stack3DIcon,
  ComputerDesktopIcon,
 } from "@heroicons/react/24/outline";
import ABTestingIcon from "./icons/ABTestingIcon";
import { Outlet } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import Sidebar from "./components/Sidebar";
import TopNavBar from "./components/TopNavBar";
import {  useState } from "react";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const menuOption = [
  {
    id: 1,
    label: "Dashboard",
    icon: <ViewColumnsIcon className="h-6 w-6" />,
    path: "/dashboard"
  },
  {
    id: 2,
    label: "Projects",
    icon: <Square3Stack3DIcon className="h-6 w-6" />,
    path: "/projects"
  },
  // {
  //   id: 3,
  //   label: "Feature",
  //   icon: <FlagIcon className="h-6 w-6" />,
  //   path: "/feature"
  // },
  // {
  //   id: 4,
  //   label: "Feature Rollout",
  //   icon: <ArrowPathIcon className="h-6 w-6" />,
  //   path: "/feature-rollout"
  // },
  // {
  //   id: 5,
  //   label: "Feature Test",
  //   icon: <ComputerDesktopIcon className="h-6 w-6" />,
  //   path: "/feature-test"
  // },
  {
    id: 6,
    label: "AB Testing",
    icon: <ABTestingIcon className="h-6 w-6" />,
    path: "/ab-testing"
  },
  {
    id: 7,
    label: "Users",
    icon: <ComputerDesktopIcon className="h-6 w-6" />,
    path: "/users/table"
  },
];

function App() {
  const [selectedMenu, setSelectedMenu] = useState(menuOption[0].id);
  const selectHandler = (id) => setSelectedMenu(id);

  return (
    <> 
    <div className="grid grid-cols-6 h-screen">
     
      <Sidebar menuOption={menuOption} onSelect={selectHandler} />
      <div className="lg:col-span-5 col-span-6 overflow-y-auto">
        <TopNavBar />
        <Outlet />
      </div>
    </div>
    <ToastContainer />
    </>
  );
}

export default App;
