import React from 'react'

const AddIcon = () => {
  return (
    <React.Fragment>
    <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6 pr-2"
    fill="currentColor"
    viewBox="0 0 512 512"
  >
    {/* ! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.  */}
    <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
  </svg>
    </React.Fragment>
  )
}

export default AddIcon