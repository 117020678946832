import {  useEffect, useState } from "react";
import {
  CalendarIcon,
  PlusSmallIcon,
  UserIcon,
  LightBulbIcon,
  Square2StackIcon,
} from "@heroicons/react/24/outline";
import axiosInstance from "../../apiEndpoints";
import { formatDate } from "../../utility";
import { Link } from "react-router-dom";
import NewProject from "./NewProject";
import BagIcon from "../../icons/BagIcon";
import { getLocalStorage } from "../../constants/utility";

const ListProject = () => {
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const isViewer = getLocalStorage("role") === "viewer" ? true : false;

  useEffect(() => {
    getData();
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    try {
      const res = await axiosInstance.get("users/getAllUsers");
      setUsers(res?.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    try{
      // const res=await axiosInstance.get("project/getAllProjects")
      const res=await axiosInstance.get("project/getAllProjects")
      setProjects(res?.data.data);
      setFilter(res?.data.data);
      setLoading(false);
      // if(!res?.data)
      // {
      //   return <NewProject projects={projects}/>;
      // }
    }catch(err)
    {
      console.log(err)
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();
    const filterTerm = e.target.value;
    if (filterTerm === "") {
      getData();
    }
    const filterData = projects.filter(
      (ele) => ele.language?.toLowerCase() === filterTerm.toLowerCase()
    );
    setFilter(filterData);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const handleClearFilter = () => {
    setSearch("");
    getData();
  };


  if (loading) {
    return  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
  </div>
  }
  if (!projects?.length) {
    return <NewProject projects={projects} />;
  }


  

  return (
    <>
     
        <div>
          <div className="grid bg-gray-100 p-5 pt-20">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  Projects
                </h2>
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 justify-between">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                   <BagIcon/>
                    Build projects to manage your campaigns 
                  </div>
                  <span className="sm:ml-3">
                    {
                      isViewer === false && (
                        <Link
                          to="/project/create"
                          type="button"
                          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm  ">
                          <PlusSmallIcon className="h-6 w-6" />
                          Create
                        </Link>
                      ) 
                    }
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="col-span-2">
            
              {filter
                ?.filter((val) => {
                  if (search === "") {
                    return val;
                  } else if (
                    val.projectName.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return val;
                  }
                 
                })
                ?.map(
                  ({
                    projectName,
                    language,
                    environments = [],
                    createdAt,
                    id,
                    abTest,
                    userId,
                  }) => (
                    <Link key={id} to={`/project/${id}`}>
                      <div    className="flex p-5 hover:bg-gray-300 cursor-pointer border-b-2 border-gray-300 ">
                        {/* main container Start */}
                        <div className="lg:flex lg:items-center lg:justify-between">
                          <div className="min-w-0 flex-1">
                            <h2 className="flex text-lg font-semibold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
                              <button
                                type="button"
                                className="rounded-md bg-indigo-600 p-1 mx-1 text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-gray-800">
                                <LightBulbIcon className="h-6 w-6" />
                              </button>
                              {projectName}
                            </h2>
                            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                              {/* <div className="mt-2 flex items-center text-sm text-gray-500">
                                <UserIcon className="h-6 w-6" />
                                Created By{" "}
                                { users?.find((user) => user.id === userId)?.fullname
                                  ? users?.find((user) => user.id === userId)?.fullname?.charAt(0)?.toUpperCase() + 
                                    users?.find((user) => user.id === userId)?.fullname?.slice(1)
                                  : "Unknown User"
                                }    
                              </div> */}
                              <div className="mt-2 flex items-center text-sm text-gray-500">
                                <UserIcon className="h-6 w-6" />
                                Created By{" "}
                                {users.find((user) => user.id === userId)
                                  ? users.find((user) => user.id === userId).fullname?.charAt(0)?.toUpperCase() +
                                    users.find((user) => user.id === userId).fullname?.slice(1)
                                  : "Unknown User"}
                              </div>

                              <div className="mt-2 flex items-center text-sm text-gray-500">
                                <CalendarIcon className="h-6 w-6" />
                                Created on {formatDate(createdAt)}
                              </div>
                              <div className="mt-2 flex items-center text-sm text-gray-500">
                                <Square2StackIcon className="h-6 w-6" />
                                No. of Campaings {abTest}
                              </div>
                            </div>
                            {/* <h4 className="text-base pt-2 leading-7 text-gray-900 sm:truncate sm:text-base sm:tracking-tight pl-1">
                              A/B Test - {abTest}
                            </h4> */}
                          </div>
                          <div className="mt-5 flex lg:mt-0 lg:ml-4 border-l-2 divide-gray-200 px-2">
                            <div className="grid grid-cols-1 mx-2 px-2">
                              <div className="col-span-1 pb-3">
                                <span className="text-sm text-gray-500">
                                  Langauge
                                </span>
                                <h4 className="text-base leading-7 text-gray-900 sm:truncate sm:text-base sm:tracking-tight">
                                  {language}
                                </h4>
                              </div>
                              <div className="col-span-1 pb-3">
                                <span className="text-sm text-gray-500">
                                  Environments
                                </span>
                                <h4 className="text-base leading-7 text-gray-900 sm:truncate sm:text-base sm:tracking-tight">
                                  {environments
                                    .map(
                                      ({ environmentName }) => environmentName
                                    )
                                    .join(", ")}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* main container End */}
                      </div>
                    </Link>
                  )
                )}
                {filter && filter.filter(val => {
                  if (search === "") {
                    return val;
                  } else if (val.projectName.toLowerCase().includes(search.toLowerCase())) {
                    return val;
                  }
                }).length === 0 && 
                <div className="flex items-center justify-center h-64">
                <div className="text-gray-500">
                 
                  <h3 className="text-lg text-center font-semibold">No data found</h3>
                  <p className="mt-2 text-center">Please refine your search criteria.</p>
                </div>
              </div>
              
              }         
                   </div>
            <div className="col-span-1 relative bg-gray-200 p-5 min-h-[calc(100vh-170px)]">
              <div className="block pb-5">
                <label
                  className="block text-sm font-medium text-gray-700">
                  Search Project
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="search"
                    name="Search"
                    value={search}
                    onChange={(e) => handleSearch(e)}
                    className="block w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Search"
                  />
                </div>
              </div>
              {/* <div className="block pb-5">
            <label
              className="block text-sm font-medium text-gray-700"
            >
              Sort By
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <select
                id="currency"
                name="currency"
                value={}
                className="block w-full px-2 py-3 rounded-md border-gray-300 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                <option>Select Project Name</option>
                <option>Create Time</option>
                <option>Project Time</option>
                <option>Creator Time</option>
                <option>Modification Time</option>
              </select>
            </div>
          </div> */}
              <div className="block pb-5">
                <label
                  className="block text-sm font-medium text-gray-700">
                  Filter by Languages
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <select
                    id="currency"
                    name="currency"
                    // value=""
                    onChange={(e) => handleFilter(e)}
                    className="block w-full px-2 py-3 rounded-md border-gray-300 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                    <option value="">Select</option>
                    <option value="node.js">Node Js</option>
                    <option value="python">Python</option>
                    <option value="php">Php</option>
                    <option value="java">Java</option>
                  </select>
                </div>
              </div>
              <button
                onClick={handleClearFilter}
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 ">
                Clear All the Filters
              </button>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default ListProject;
