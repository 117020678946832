import React,{useEffect,useState} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { successToast } from '../../constants/toasts';
import axiosInstance from '../../apiEndpoints';

const CreateUser = () => {
  const navigate=useNavigate();
  const [user,setUser]=useState('')
  const { userId } = useParams();

  useEffect(() => {
    if(!userId) return;
    getData();
  },[]);

  const getData = async() => {
    try{
      const result= await axiosInstance.get(`users/getUserById/${userId}`)
      setUser(result.data.data)
    }catch(err)
    {
      console.log(err)
    }
   };

  if (userId && !user) {
    return <div>Loading...</div>;
  }

  const roles = [
    { label: 'User', value: 'user' },
    { label: 'Admin', value: 'admin' },
    { label: 'Viewer', value: 'viewer' }
  ];

  return (
   <React.Fragment>
   <ToastContainer/>
    <div className="grid  p-2 pt-20">
      <div className="grid bg-gray-100 p-3 ">
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          Create User
        </h2>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4">
        <span className="sm:ml-3"></span>
        <div className="relative ml-3 sm:hidden">
          <button type="button"
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            id="mobile-menu-button" aria-expanded="false" aria-haspopup="true">
            More
            <svg className="-mr-1 ml-2 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor" aria-hidden="true">
              <path fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd" />
            </svg>
          </button>
          <div
            className="absolute right-0 z-10 mt-2 -mr-1 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu" aria-orientation="vertical" aria-labelledby="mobile-menu-button" tabIndex="-1">
            <Link className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1"
              id="mobile-menu-item-0">Edit</Link>
            <Link className="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabIndex="-1"
              id="mobile-menu-item-1">View</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
      <div className="w-1/2  mx-auto mt-5">
        <Formik
          initialValues={userId ? {
            fullname:user.fullname,
            email:user.email,
            role:user.role
        }:
            {  fullname: "" ,email: '', password: '', role: "" }
          }
          validationSchema={Yup.object(userId ?
            {
              email: Yup.string().required('Email is required'),
              fullname: Yup.string().required('Full name is required'),
              role: Yup.string().required('Role is required'),
            }:
            {
            email: Yup.string()
            .email('Invalid email address')
            .required('Email is required')
            .matches(
              /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
              'Email must be a valid email address with "@" symbol'
            ),
            fullname: Yup.string().required('Full name is required'),
            password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters long')
            .matches(
              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/,
              'Password must contain at least one letter, one number, and one special character'
            )
            .test('not-equal', 'Email and password cannot be the same', function (value) {
              const { email } = this.parent;
              return value !== email;
            }),
            role: Yup.string().required('Role is required'),
          })}

          onSubmit={async(values, { setSubmitting }) => {
            if(userId)
            { try{
               await axiosInstance.put(`users/updateUser/${userId}`, values);
              toast("Update Successfully",successToast);
              setSubmitting(false);
              navigate('/users/table'); 
            }catch(err)
            {
              console.log(err)
            }
             }
            else{
              try{
                values.status="active"
               const res= await axiosInstance.post("users/createUser",values)
                toast("Register Successfully",successToast)
                setSubmitting(false);
                navigate('/users/table'); 
              }catch(err)
              {
                console.log(err)
              }
            }
            }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit,isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="bg-white shadow-inner border border-indigo-700 rounded px-8 pt-6 pb-8 mb-4 ">
              <div className="mb-4">
                <label htmlFor="fullname" className="block text-gray-700 font-bold mb-2">
                  Full Name
                </label>
                <Field
                  type="text"
                  name="fullname"
                  id="fullname"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage name="fullname">
                  {(msg) => <div className="text-red-500">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="mb-4">
                <label htmlFor="fullname" className="block text-gray-700 font-bold mb-2">
                 Email
                </label>
                <Field
                  type="email"
                  name="email"
                 disabled={userId? true:false}
                  id="email"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage name="email">
                  {(msg) => <div className="text-red-500">{msg}</div>}
                </ErrorMessage>
              </div>
              {
                !userId && 
<div className="mb-4">
                <label htmlFor="password" className="block text-gray-700 font-bold mb-2">
                  Password
                </label>
                <Field
                  type="password"
                  name="password"
                  
                  id="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <ErrorMessage name="password">
                  {(msg) => <div className="text-red-500">{msg}</div>}
                </ErrorMessage>
              </div>
              }
              
              <div className="mb-4">
                <label htmlFor="role" className="block text-gray-700 font-bold mb-2">
                  Role
                </label>
                <Field
                  as="select"
                  name="role"
                 
                  id="role"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight
                  focus:outline-none focus:shadow-outline"
                  >
                    <option>Select</option>
                    {roles.map((role) => (
                      <option key={role.value} value={role.value}>
                        {role.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="role">
                    {(msg) => <div className="text-red-500">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="flex items-center justify-between">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    {
                      userId ? "Update" : "Register"
                    }
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      </React.Fragment>
);
};

export default CreateUser;      