import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getLocalStorage } from "../constants/utility";
import logo  from './../assests/images/output-onlinepngtools.png'

const Sidebar = ({ menuOption, onSelect }) => {
  const location = useLocation();
  const [active, setActive] = useState(location.pathname);
  const handleClick = (id) => () => {
    setActive(id);
    onSelect(id);
  };

  const isUser = getLocalStorage("role") === "user" ? true : false;
  const isViewer = getLocalStorage("role") === "viewer" ? true : false;

  return (
    <div className="lg:col-span-1 col-span-6">
      <aside className="h-screen relative z-50" aria-label="Sidebar">
        <div className="px-3 py-4 overflow-y-auto bg-indigo h-full bg-gray-800">
          <div className="flex flex-shrink-0 items-center px-2 pb-3">
            <Link to="/dashboard" className="flex">
              <img
                className="block h-8 w-auto lg:hidden"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&amp;shade=500"
                alt="Your Company"
              />
              
              <span
                href="#"
                className="text-white px-3 py-2 rounded-md text-xl  font-bold">
                Ab Testing
              </span>
            </Link>
          </div>
          <ul className="space-y-2">
            {menuOption
              .filter((ele) => {
                if (isUser) {
                  return ele.label !== "Users";
                }
                if(isViewer){
                  return (ele.label !== "Users");
                }
                return ele;
              })
              ?.map(({ id, label, icon, path }) => (
                <li row={id} key={id}>
                  <Link
                    to={path}
                    onClick={handleClick(path)}
                    className={`${
                      path === active && "bg-indigo-700"
                    } flex items-center p-2 text-base text-gray-300 hover:text-white transition duration-75 rounded-lg group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 cursor-pointer`}>
                    <span className="rounded-lg bg-indigo-700 p-1">{icon}</span>
                    <span className="ml-3">{label}</span>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
