import  axiosInstance  from "../apiEndpoints"

export const createCampaign = async (data) => {
    try{
        const res = await axiosInstance.post("campaign/createABTesting", data);
        return res;
    }catch(err)
    {
        console.log(err)
    }
}
