const ABTestingIcon = () => (
  <svg
    className="h-6 w-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 512 512"
    width="512"
    height="512"
  >
    <g id="_02-ab_test" data-name="02-ab test">
      <g id="linear_color" data-name="linear color">
        <path d="M480,4H32A28.032,28.032,0,0,0,4,32V384a28.032,28.032,0,0,0,28,28H206.406l-6.375,50.977a240.458,240.458,0,0,0-46.687,23.039A12,12,0,0,0,160,508H352a12,12,0,0,0,6.656-21.984,240.458,240.458,0,0,0-46.687-23.039L305.594,412H480a28.032,28.032,0,0,0,28-28V32A28.032,28.032,0,0,0,480,4ZM32,28H480a4,4,0,0,1,4,4V308H28V32A4,4,0,0,1,32,28ZM212.659,484c13.406-4.555,28.463-8,43.341-8s29.935,3.445,43.341,8Zm74.2-28.424a134.919,134.919,0,0,0-61.714,0L230.593,412h50.814ZM484,384a4,4,0,0,1-4,4H32a4,4,0,0,1-4-4V332H484Z" />
        <path d="M240,372h32a12,12,0,0,0,0-24H240a12,12,0,0,0,0,24Z" />
        <path d="M67.126,250.966a12,12,0,0,0,15.84-6.092L101.132,204h69.736l18.166,40.874a12,12,0,1,0,21.932-9.748l-64-144a12,12,0,0,0-21.932,0l-64,144A12,12,0,0,0,67.126,250.966ZM136,125.546,160.2,180H111.8Z" />
        <path d="M256,283a12,12,0,0,0,12-12V64a12,12,0,0,0-24,0V271A12,12,0,0,0,256,283Z" />
        <path d="M320,252h76a47.995,47.995,0,0,0,15.864-93.294A47.976,47.976,0,0,0,372,84H320a12,12,0,0,0-12,12V240A12,12,0,0,0,320,252Zm100-48a24.028,24.028,0,0,1-24,24H332V180h64A24.028,24.028,0,0,1,420,204Zm-88-96h40a24,24,0,0,1,0,48H332Z" />
      </g>
    </g>
  </svg>
);

export default ABTestingIcon;
