import React, { useEffect, useState } from 'react';
import axiosInstance from '../../apiEndpoints';
import Cards from './Cards';
import DashboardChart from './DashboardChart';

import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = () => {
  const [cardData, setCardData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCardData();
  }, []);

  const getCardData = async () => {
    try {
      const { data } = await axiosInstance.get('project/getDashboardDetails');
      setCardData(data.data);
      setLoading(false);
    } catch (error) {
      console.log('Error fetching card data:', error);
    }
  };

  if (loading) {
    return  <div className="flex justify-center items-center h-screen">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
            </div>
  }

  return (
    <React.Fragment>
     <ToastContainer />
    <div className="bg-gray-100 min-h-full">
    
      <div className="grid p-2 pt-20">
        <div className="grid p-5">
          {cardData && <Cards cardData={cardData} />}
          {/* <DashboardChart /> */}
        </div>
      </div>
    </div>
    </React.Fragment>
  );
};

export default Dashboard;
