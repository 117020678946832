import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axiosInstance from "../../apiEndpoints";
import { headers, summaryHeaders } from "../../constants/headers";
import ReportHeaderSection from "./ReportHeaderSection";
import DeleteModal from "../Modals/DeleteModal";

const CampaignReporting3 = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [campaignValue, setCampaignValue] = useState([]);
  const [summaryGoalFilterData, setSummaryGoalFilterData] = useState([]);
  const { campaignId } = useParams();
  const [finalObj, setFinalObj] = useState({});
  const [allGoals, setAllGoals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [refreshLoader, setRefreshLoader] = useState(false);
  const [csvData, setCSVData] = useState("");


  useEffect(() => {
    getCampaignData();
    getReportingData();
  }, []);

  const getCampaignData = async () => {
    const { data } = await axiosInstance.get(
      `campaign/getABTestingById/${campaignId}`
    );
    const dataResponse = data.data;
    setAllGoals(dataResponse?.goals);
    setCampaignValue(dataResponse);
    const controlObj = {
      name: "Control",
    };
    const transformedArr = dataResponse.variations.map((obj) => {
      const { label } = obj;
      return { name: label };
    });
    const variationsArr = [...transformedArr, controlObj];
    setCampaignData(variationsArr);
    setLoader(false);
  };

  const getReportingData = async () => {
    try {
      let { data } = await axiosInstance.get(
        `tracking/getTrackingByCampaignId/${campaignId}`
      );
      let dataResponse = data.data;
      setSummaryGoalFilterData(dataResponse);

      const transformedData = dataResponse.reduce((result, entry) => {
        const date = new Date(entry.createdAt);
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${date.getFullYear()}`;
 
        const existingEntryIndex = result.findIndex(
          (item) =>
            item.date === formattedDate &&
            item.variation === entry.variation &&
            item.goal === entry.goal
        );
 
        if (existingEntryIndex !== -1) {
          result[existingEntryIndex].count++;
        } else {
          result.push({
            variation: entry.variation,
            date: formattedDate,
            goal: entry.goal,
            count: 1,
          });
        }
        return result;
      }, []);
 
      const sortedTransformedDataArr = transformedData?.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
 
      setCSVData(sortedTransformedDataArr);
      const result = dataResponse.reduce((accumulator, item) => {
        const { goal, variation } = item;
        // Create goal if not exists
        accumulator[goal] = accumulator[goal] || {};
        // Create variation if not exists in the goal
        accumulator[`${goal}-${variation}`] =
          (accumulator[`${goal}-${variation}`] || 0) + 1;
        return accumulator;
      }, {});
      setFinalObj(result);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteClick = async () => {
    try {
      setIsOpen(true);
      let obj = {
        status: "deleted",
      };
      await axiosInstance.put(`campaign/updateABTesting/${campaignId}`, obj);
      navigate("/ab-testing");
    } catch (err) {
      console.log(err);
    }
  };

  const handleCampaignStatus = async () => {
    try {
      // Set loading to true immediately
      setLoading(true);
      // Perform the asynchronous operation based on the campaign status
      let obj = {};
      if (campaignValue.status === "inactive") {
        obj = { status: "active" };
      } else {
        obj = { status: "inactive" };
      }
      await axiosInstance.put(`campaign/updateABTesting/${campaignId}`, obj);
      await getCampaignData();
    } catch (err) {
      // Handle errors
      console.error("An error occurred:", err);
    } finally {
      // Set loading to false after the asynchronous operation, whether it succeeds or fails
      setLoading(false);
    }
  };

  const handleRefreshClick = async () => {
    try {
      setRefreshLoader(true)
      await getReportingData();
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally{
      setTimeout(()=>{
        setRefreshLoader(false)
      },500) 
    }
  };

  if (loader) {
    return  <div className="flex justify-center items-center h-screen">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
            </div>
  }

  return (
    <>
      <DeleteModal
        isOpen={isOpen}
        handleDeleteClick={handleDeleteClick}
        setIsOpen={setIsOpen}
      />

      <ReportHeaderSection
        handleCampaignStatus={handleCampaignStatus}
        loading={loading}
        campaignValue={campaignValue}
        setIsOpen={setIsOpen}
        campaignId={campaignId}
        data={csvData}
        headers={headers}
        summaryData={summaryGoalFilterData}
        summaryHeaders={summaryHeaders}
        handleRefreshClick={handleRefreshClick}
        refreshLoader={refreshLoader}
      />

      <div className="flex flex-col p-4 mb-6">
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="border-collapse border border-slate-400 min-w-full text-left text-sm font-light">
                <tbody>
                  <tr className="border-b bg-neutral-100 light:border-neutral-500 light:bg-neutral-700">
                    <th className="whitespace-nowrap px-6 py-4  border border-slate-300">Goals</th>
                    {campaignData.map((ele) => (
                      <th
                        key={ele.name}
                        className="whitespace-nowrap px-6 py-4">
                        {ele.name}
                      </th>
                    ))}
                  </tr>
                  {allGoals?.map((element, index) => (
                    <tr className="border-b bg-white light:border-neutral-500 light:bg-neutral-600">
                      <th className="whitespace-nowrap px-6 py-4 font-medium border border-slate-300">
                        {element.goalTitle || element.goalIdentifier}
                      </th>
                      {campaignData.map((ele) => (
                        <th
                          key={ele.name}
                          className="whitespace-nowrap px-6 py-4">
                          {finalObj[`${element.goalIdentifier}-${ele.name}`] ||
                            0}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignReporting3;
