import React from 'react'

const DeleteModal = ({isOpen,handleDeleteClick,setIsOpen}) => {
  return (
    <div
    className={`fixed inset-0 flex items-center justify-center z-10 ${
      isOpen ? "visible" : "invisible"
    }`}>
    <div className="fixed inset-0 bg-black  opacity-20" />
    <div className="modal-content bg-white w-1/3 shadow-custom rounded-lg p-6 relative">
      <h2 className="text-2xl font-bold mb-4">
        Are you sure you want to delete?
      </h2>
      <div className="modal-actions flex justify-end">
        <button
          className="modal-btn cursor-pointer bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded mr-2"
          onClick={handleDeleteClick}>
          Delete
        </button>
        <button
          className="modal-btn cursor-pointer bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
          onClick={() => setIsOpen(false)}>
          Cancel
        </button>
      </div>
    </div>
  </div>  )
}

export default DeleteModal