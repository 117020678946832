import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axiosInstance from "../../apiEndpoints";
import {
  generateVariationKey,
  getRandomColor,
  getStringDate,
} from "../../constants/utility";
import { getDateWiseData } from "../../constants/calculation";

const endDate = new Date().getDate();

const DashboardChart = () => {
  const [graphData, setGraphData] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignId, setCampaignId] = useState("");

  useEffect(() => {
    getCampaignList();
  }, []);

  useEffect(() => {
    if (campaignId) {
      getReportingData();
    }
  }, [campaignId]);

  const getCampaignList = async () => {
    try {
      const { data } = await axiosInstance.post("/campaign/data");
      setCampaignList(data);
      if (data.length > 0) {
        setCampaignId(data[0].id);
      }
    } catch (err) {
      console.log("err", err);
      
    }
  };

  const getReportingData = async () => {
    try {
      const { data } = await axiosInstance.get(`tracking/${campaignId}`);
      const dateRangeFilter = data.filter((ele) => {
        const createdAt = getStringDate(ele.createdAt);
        return endDate - 5 <= createdAt && endDate >= createdAt;
      });

      const triggerFilterData = dateRangeFilter.filter(
        (ele) => ele.event === "trigger_variation"
      );
      const showFilterData = dateRangeFilter.filter(
        (ele) => ele.event === "show_variation"
      );

      const triggerGraphData = getDateWiseData(triggerFilterData);
      const showGraphData = getDateWiseData(showFilterData);

      const result = triggerGraphData.map((triggerItem, index) => {
        const showItem = showGraphData[index];

        const newItem = { date: triggerItem.date };

        for (const key in triggerItem) {
          if (key !== "date") {
            newItem[key] = parseInt(
              ((triggerItem[key] / showItem[key]) * 100).toFixed()
            );
          }
        }

        return newItem;
      });

      setGraphData(result);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleChange = (e) => {
    setCampaignId(e.target.value);
  };


  // if (graphData.length === 0) {
  //   return (
  //     <div className="flex items-center justify-center h-64">
  //       <div className="text-gray-500">
  //         <h3 className="text-lg text-center font-semibold">No data found</h3>
  //         <p className="mt-2 text-center">Please create projects and campaigns</p>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <React.Fragment>
      <div className="mt-10 bg-white rounded-lg">
        <div className="p-5">
          <label className="text-lg p-5 font-medium text-gray-700">
            Conversion Data
          </label>
          <select
            onChange={handleChange}
            value={campaignId}
            className="px-7 py-1  rounded-md border-2 border-black text-gray-500 sm:text-sm">
            {campaignList.map(({ id, campaignKey }) => (
              <option key={id} value={id}>
                {campaignKey}
              </option>
            ))}
          </select>
        </div>
        {
          (graphData.length === 0) ?
          <div className="flex items-center justify-center h-64">
         <div className="text-gray-500">
          <h3 className="text-lg text-center font-semibold">No data found</h3>
          <p className="mt-2 text-center">Please create projects and campaigns</p>
        </div>
      </div>:
      <ResponsiveContainer width="100%" height={450}>
      <LineChart
        data={graphData}
        margin={{ top: 20, right: 30, left: 0, bottom: 20 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis ticks={[0, 20, 40, 60, 80, 100]} />
        <Legend />
        <Tooltip formatter={(value) => `${value}%`} />
        {graphData.length > 0 &&
          Object.keys(graphData[0])?.map((key, index) => {
            if (key !== "date") {
              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={key}
                  name={key}
                  stroke={getRandomColor()}
                />
              );
            }
            return null;
          })}
      </LineChart>
    </ResponsiveContainer>
        }
        
      </div>
    </React.Fragment>
  );
};

export default DashboardChart;
