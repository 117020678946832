import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { createProject, updateProject } from "../../services/project.service";
import { v4 as uuidv4 } from "uuid";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../apiEndpoints";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { errorToast, successToast } from "../../constants/toasts";
import CopyIcon from "../../icons/CopyIcon";
import { useNavigate } from "react-router-dom";
import BagIcon from "../../icons/BagIcon";
import { getLocalStorage } from "../../constants/utility";
import { removeWhiteSpaceAndLowerCase } from "../../utility";

const validationSchema = Yup.object().shape({
  environments: Yup.array().of(
    Yup.object().shape({
      environmentName: Yup.string().required("Required"),
      isDefault: Yup.boolean(),
    })
  ),
  projectName: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Required"),
  language: Yup.string().required("Required"),
});

const CreateProject = () => {
  const { projectId } = useParams();
  const [project, setproject] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [domainList, setDomainList] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [allProjects, setAllProjects] = useState("");
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [isNewProject, setIsNewProject] = useState(true);

  const [isProjectNameUnique, setIsProjectNameUnique] = useState(true);
  const [currentProjectName, setCurrentProjectName] = useState("");

  const isViewer = getLocalStorage("role") === "viewer" ? true : false;

  const inputRef = useRef(null);
  const navigate = useNavigate();

  const handleAddCustomDomain = (e, newUrl) => {
    e.preventDefault();
    if (newUrl?.trim() != "") {
      setDomainList((prevList) => [...prevList, { urls: newUrl }]);
    }
  };

  const handleRemoveDomain = (index) => {
    setDomainList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleDeleteClick = async () => {
    setIsOpen(true);
    let obj = {
      status: "deleted",
    };
    const response = await updateProject(projectId, obj);
    if (response.data.message === "Project cannot be deleted") {
      toast(response.data.message, errorToast);
      setIsOpen(false);
    } else {
      navigate("/projects");
    }
  };

  useEffect(() => {
    if (!projectId) return;
    const getData = async () => {
      try {
        const res = await axiosInstance.get(
          `project/getProjectById/${projectId}`
        );
        setproject(res?.data.data);
        setCurrentProjectName(res?.data.data.projectName);
        setDomainList(res?.data?.domainWhitelistUrls);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [projectId]);

  // Function to handle the copy operation
  const handleCopy = (e, sdkKey) => {
    e.preventDefault();
    if (sdkKey) {
      inputRef.current.select();
      navigator.clipboard
        .writeText(sdkKey)
        .then(() => {
          toast("Copied to clipboard!", successToast);
        })
        .catch((error) => {
          console.error("Copy failed:", error);
        });
    }
  };

  const getAllProjects = async () => {
    try {
      const res = await axiosInstance.get("project/getAllProjects");
      setAllProjects(res?.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCampaigns = async () => {
    try {
      const res = await axiosInstance.get("campaign/getAllABTesting");
      setAllCampaigns(res?.data.data);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
    }
  };

  const handleChangeProjectName = (event, setFieldValue) => {
    const currentProjectValue = event.target.value
    const isNameUsed = allProjects?.some(
      (ele) =>
        removeWhiteSpaceAndLowerCase(ele.projectName) ===
        removeWhiteSpaceAndLowerCase(currentProjectValue)
    );
    if (isNameUsed && removeWhiteSpaceAndLowerCase(currentProjectName) !== removeWhiteSpaceAndLowerCase(currentProjectValue)) {
      toast(
        `${currentProjectValue} name already been used, try some other name`,
        errorToast
      );
    } else {
      setProjectName(currentProjectValue);
      setFieldValue("projectName", currentProjectValue);
    }
  };

  const handleChangeEnvironmentName = (event, setFieldValue, index) => {
    const { value } = event.target;

    // Check if the project is associated with any campaign
    const isProjectLinked = allCampaigns.some(
      (campaign) => campaign.projectId === projectId
    );

    if (isProjectLinked) {
      // If the project is associated with any campaign, check if the environment is linked in any campaign for other projects
      const isEnvironmentLinkedInOtherProject = allCampaigns.some(
        (campaign) =>
          campaign.environments === value && campaign.projectId !== projectId
      );

      // If the environment is linked in any other project's campaign, display a message or toast and return
      if (isEnvironmentLinkedInOtherProject) {
        toast(
          "Environment name is used in a campaign for another project. Cannot update.",
          errorToast
        );
        return;
      }
    }

    // Update the environment name if it's not used in any campaign for any project
    setFieldValue(`environments[${index}].environmentName`, value);
  };

  const removeEnvironment=(remove,index,sdkKey)=>
  {
       const isEnvUsed=allCampaigns.some((campaign)=>campaign.sdk_Key===sdkKey)
       if(isEnvUsed)
       {
         toast("Environment used in a campaign cannot be removed.",errorToast)
       }else{
        remove(index)
       }
 }

  useEffect(() => {
    getAllCampaigns();
    getAllProjects();
  }, [isProjectNameUnique]);

  if (projectId && !project) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
      </div>
    );
  }
  return (
    <React.Fragment>
      <ToastContainer />
      <div
        className={`fixed inset-0 flex items-center justify-center z-10 ${
          isOpen ? "visible" : "invisible"
        }`}>
        <div className="fixed inset-0 bg-black  opacity-20" />
        <div className="modal-content bg-white w-1/3 shadow-custom rounded-lg p-6 relative">
          <h2 className="text-2xl font-bold mb-4">
            Are you sure you want to delete?
          </h2>
          <div className="modal-actions flex justify-end">
            <button
              className="modal-btn cursor-pointer bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded mr-2"
              onClick={handleDeleteClick}>
              Delete
            </button>
            <button
              className="modal-btn cursor-pointer bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
              onClick={() => setIsOpen(false)}>
              Cancel
            </button>
          </div>
        </div>
      </div>

      <Formik
        // new code
        initialValues={
          projectId
            ? {
                projectName: project.projectName,
                language: project.language,
                environments: project.environments,
              }
            : {
                language: "",
                projectName: "",
                domainWhitelistUrls: domainList?.map((item) => ({
                  urls: item.urls,
                })),
                environments: [
                  { environmentName: "", sdkKey: uuidv4(), isDefault: false },
                ],
              }
        }
        validationSchema={validationSchema}
        // validate={values => {
        //   console.warn("values",values)
        // }}
        onSubmit={async (data) => {
          if (projectId) {
            const result = await updateProject(projectId, data);
            // if (result.data.message === "Project cannot be deleted") {
            //   toast("Project cannot be updated", errorToast);
            // }
            if (result.data.message === "Project updated successfully") {
              toast(result.data.message, successToast);
            } else {
              navigate("/projects");
              toast(result.data.message, errorToast);
            }
            console.warn("result", result);
          } else {
            data.domainWhitelistUrls = domainList?.map((item) => ({
              urls: item.urls,
            }));
            data.abTest = 0;
            data.userId = getLocalStorage("id");
            await createProject(data);
          }
          navigate("/projects");
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid bg-gray-100 p-5 pt-20 ">
              {/*  project heading  */}

              <div className="lg:flex lg:items-center lg:justify-between">
                <div className="min-w-0 flex-1">
                  <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    {projectId ? `${values.projectName}` : "Create New Project"}
                  </h2>
                  <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <BagIcon />
                      New Project
                    </div>
                  </div>
                </div>
              </div>
              {/*  project heading end */}
            </div>
            <div className="grid grid-cols-3 ">
              <div className="col-span-2 p-5">
                <div className="lg:flex flex-col">
                  <div className="min-w-0 flex-1 pb-5">
                    <h4 className="text-xl font-bold leading-7 text-gray-900 pb-5 sm:truncate sm:text-2xl sm:tracking-tight">
                      Define the Project Name and Environments, Select Languages
                    </h4>
                    <div className="grid grid-cols-2 gap-10">
                      <div>
                        <label
                          htmlFor="projectName"
                          className="block text-sm font-medium text-gray-700">
                          Project Name
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <Field
                            type="text"
                            name="projectName"
                            value={values.projectName}
                            onChange={(event) => {
                              handleChangeProjectName(event, setFieldValue);
                            }}
                            onBlur={handleBlur}
                            disabled={
                              isViewer ||
                              allCampaigns.some(
                                (campaign) =>
                                  campaign.projectName === values.projectName
                              )
                            }
                            className={
                              errors.projectName
                                ? "block w-full px-2 py-3  rounded-md border-red-500 focus:border-red-500 focus:ring-red-500 sm:text-sm border-2"
                                : "block w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                            }
                            placeholder="Name"
                          />
                          {touched.projectName && errors.projectName && (
                            <div className="text-sm text-red-700 ">
                              {errors.projectName}
                            </div>
                          )}
                          <div className="absolute inset-y-0 right-0 flex items-center">
                            <label className="sr-only"></label>
                          </div>
                        </div>
                        {
                          allCampaigns.some(
                            (campaign) =>
                              campaign.projectName === values.projectName
                          ) && 
                          <p className="text-sm text-red-700 ">Project name cannot be updated because it is used in a campaign</p>

                        }
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Languages
                        </label>
                        <div className="relative mt-1 rounded-md shadow-sm">
                          <Field
                            name="language"
                            as="select"
                            value={values.language}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={
                              isViewer ||
                              allCampaigns.some(
                                (campaign) =>
                                  campaign.projectName === values.projectName
                              )
                            }
                            className={
                              errors.language
                                ? "block w-full px-2 py-3 rounded-md border-red-500 text-gray-500 focus:border-red-500 focus:ring-red-500 sm:text-sm border-2"
                                : "block w-full px-2 py-3 rounded-md border-gray-300 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                            }>
                            <option>Select</option>
                            <option value="Node.js">Node.js</option>
                            <option value="Python">Python</option>
                          </Field>
                          {touched.language && errors.language && (
                            <div className="text-sm text-red-700 ">
                              {errors.language}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="min-w-0 flex-1 pb-5">
                    <h4 className="text-xl font-bold leading-7 text-gray-900 pb-5 sm:truncate sm:text-2xl sm:tracking-tight">
                      Environments
                    </h4>
                    <span className="text-base text-gray-500 py-2 rounded-md font-medium">
                      Environments help you organize and manage your campaigns
                      and lets you choose where you wish to run the campaigns.
                      Common environments within a project could be Development,
                      Staging, or Production.
                    </span>
                    <FieldArray name="environments">
                      {({ push, remove }) => (
                        <div>
                          {values?.environments?.map((data, index) => (
                            <div key={index} className="border p-5 my-4">
                              <div className="grid grid-cols gap-10">
                                <div className="col-span-1">
                                  <div className=" flex justify-between">
                                    <div className=" w-1/2 mx-2">
                                      <label className=" text-sm font-medium text-gray-700">
                                        Environment {index + 1}:
                                      </label>
                                      <div className="relative mt-1 rounded-md shadow-sm ">
                                        <input
                                          className=" w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                          type="text"
                                          name={`environments[${index}].environmentName`}
                                          value={data.environmentName}
                                         
                                          disabled={
                                            isViewer
                                          }
                                          onChange={(event) =>
                                            handleChangeEnvironmentName(
                                              event,
                                              setFieldValue,
                                              index
                                            )
                                          }
                                          onBlur={handleBlur}
                                        />
                                        {touched.environments &&
                                          errors.environments &&
                                          errors.environments[index] &&
                                          errors.environments[index]
                                            .environmentName && (
                                            <div className="text-sm text-red-700 ">
                                              {
                                                errors.environments[index]
                                                  .environmentName
                                              }
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                    {projectId && (
                                      <div className="w-1/2">
                                        <label className=" text-sm font-medium text-gray-700">
                                          SDK key
                                        </label>
                                        <div className="relative mt-1 rounded-md shadow-sm">
                                          <div className="flex">
                                            <input
                                              className=" w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                              type="text"
                                              disabled
                                              name={`environments[${index}].sdkKey`}
                                              value={data.sdkKey}
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              ref={inputRef}
                                            />

                                            <button
                                              className="ml-2 flex items-center px-3 py-1  rounded bg-gray-100 hover:bg-gray-300 focus:bg-gray-300"
                                              onClick={(e) =>
                                                handleCopy(e, data.sdkKey)
                                              }>
                                              <CopyIcon />
                                            </button>
                                          </div>
                                        </div>{" "}
                                      </div>
                                    )}
                                  </div>

                                  <div className="flex py-2">
                                    <div>
                                      <div className="form-check">
                                        <input
                                          className="form-checkbox h-3 w-5 cursor-pointer "
                                          type="checkbox"
                                          name={`environments[${index}].isDefault`}
                                          onChange={handleChange}
                                        />
                                        <label className="form-check-label inline-block text-gray-800">
                                          Set as Default:
                                        </label>
                                      </div>
                                      {values.environments.length > 1 && (
                                        <button
                                          className="items-center justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mt-4"
                                          type="button"
                                          onClick={() =>
                                            removeEnvironment(
                                              remove,
                                              index,
                                              data.sdkKey
                                            )
                                          }>
                                          Remove
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                            <div
                              onClick={() =>
                                push({
                                  environmentName: "",
                                  isDefault: false,
                                  sdkKey: uuidv4(),
                                })
                              }
                              className="mt-2 flex items-center text-sm text-indigo-800 cursor-pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 pr-2"
                                fill="currentColor"
                                viewBox="0 0 512 512">
                                <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                              </svg>
                              Add another environment
                            </div>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>

                  {/* ----- Domain whitelist ---- */}

                  {/* <div className="min-w-0 flex-1 pt-6">
                    <h4 className="text-xl font-bold leading-7 text-gray-900 pb-1 sm:truncate sm:text-2xl sm:tracking-tight">
                      Domain Whitelist
                    </h4>
                    <FieldArray name="domainWhitelist">
                      <div>
                        <>
                          {domainList.length > 0 && (
                            <ul className="mt-4 ">
                              {domainList?.map((item, index) => (
                                <li key={index} className="flex border border-gray-300 rounded-md shadow-sm items-center mb-2 p-2">
                                  <span className="flex-grow">{item.urls}</span>
                                  <button
                                    onClick={() => handleRemoveDomain(index)}
                                    className="text-red-600 font-bold"
                                  >
                                    x
                                  </button>
                                </li>
                              ))}
                            </ul>
                          )}
                        </>
                        <div className="my-4">
                          <div className="grid grid-cols gap-10">
                            <div className="col-span-1">
                              <div className=" flex justify-between">
                                  <div className="w-1/2">
                                    <div className="relative mt-1 rounded-md shadow-sm">
                                      <div className="flex">
                                        <input
                                          className=" w-full px-2 py-3  rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-2"
                                          type="text"
                                          name="domainWhitelistUrls"
                                          onChange={handleChange}
                                          value={values.domainWhitelistUrls || ""}
                                          placeholder="Domain whitelist url..."
                                        />
                                          <button
                                          className="ml-2 flex items-center px-3 py-1  rounded bg-gray-100 hover:bg-gray-300 focus:bg-gray-300"
                                          onClick={(e) => {
                                            handleAddCustomDomain(e, values.domainWhitelistUrls);
                                            values.domainWhitelistUrls = '';
                                          }}>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="h-6 w-6 p-1"
                                              fill="currentColor"
                                              viewBox="0 0 512 512">
                                              <path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z" />
                                            </svg>
                                        </button> 
                                      </div>
                                    </div>{" "}
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </FieldArray>
                  </div> */}

                  {isViewer === false && (
                    <div className="flex min-w-0 space-x-3 pb-5 justify-end">
                      <div className="">
                        <button
                          type="submit"
                          disabled={!isProjectNameUnique}
                          className="items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          {projectId ? "Update" : "Create"}
                        </button>
                      </div>

                      {projectId && (
                        <div className="min-w-0 flex pb-5 justify-end">
                          <button
                            type="button"
                            className="items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setIsOpen(true)}>
                            Delete
                          </button>
                        </div>
                      )}
                      {projectId && (
                        <div className="min-w-0 flex pb-5 justify-end">
                          <button
                            type="button"
                            className="items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => navigate("/projects")}>
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-span-1 relative bg-gray-200 p-5 min-h-[calc(100vh-170px)]">
                <h3 className="text-lg font-bold leading-7 text-gray-900 pb-5 sm:truncate sm:text-2xl sm:tracking-tight">
                  Steps to integrate SDK
                </h3>
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="my-2 flex-shrink text-sm text-gray-500">
                    {/*  Heroicon name: mini/briefcase  */}
                    <span className="absolute rounded-full bg-indigo-600  h-10 w-10 mr-2 flex items-center justify-center text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-gray-800">
                      1
                    </span>
                    <span className="flex m-1 pl-12">
                      Follow the simplified code snippets to install various
                      SDKs for your preferred backend language.
                    </span>
                  </div>
                </div>
                <div className="my-1 flex sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="mt-2 flex  text-sm text-gray-500">
                    {/*  Heroicon name: mini/briefcase  */}
                    <span className="rounded-full bg-indigo-600  h-10 w-10 mr-2 flex items-center justify-center text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-gray-800">
                      2
                    </span>
                    <span className="flex">Import the SDK in your code.</span>
                  </div>
                </div>
                <div className="my-1 flex sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="mt-2 flex text-sm text-gray-500">
                    {/*  Heroicon name: mini/briefcase  */}
                    <span className="absolute rounded-full bg-indigo-600  h-10 w-10 mr-2 flex items-center justify-center text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-gray-800">
                      3
                    </span>
                    <span className="flex m-1 pl-12">
                      Retrieve the settings file based on the project
                      environments. Each environment has a distinct set of
                      campaign settings associated with it and is determined by
                      the inclusion/exclusion of project environments from the
                      campaign. For additional details and code samples,refer to
                      these documents
                    </span>
                  </div>
                  <ul>
                    <li>
                      <Link
                        to="/pythonABtest-v1.3.pdf"
                        className="flex m-1 pl-7 text-sm  text-gray-500"
                        target="_blank"
                        download>
                        <strong>Python Integration Documents</strong>
                      </Link>
                    </li>
                  </ul>
                  <span className="flex m-1 pl-7 text-md  text-gray-500">
                    <strong>Client Packages</strong>
                  </span>
                  <ul>
                    <li>
                      <Link
                        to="/abtestClient-v1.4.py"
                        className="flex m-1 pl-7 text-sm  text-gray-500"
                        target="_blank">
                        <strong>Python Client package v-1.4</strong>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default CreateProject;
